export const tableColumnsTopCreators = [
  {
    Header: "کاربر",
    accessor: "name",
  },
  {
    Header: "تعداد پیام",
    accessor: "artworks",
  },
  {
    Header: "رتبه",
    accessor: "rating",
  },
];

export const tableDataTopCreators =[
  {
    "name": [
      "علی رضامنش",
      "https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80"
    ],
    "artworks": "9821",
    "rating": 20
  },
  {
    "name": [
      "امیرحسین سیفی",
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
    ],
    "artworks": "7032",
    "rating": 50
  },
  {
    "name": [
      "حسام آشوری",
      "https://images.unsplash.com/photo-1573766064535-6d5d4e62bf9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80"
    ],
    "artworks": "5204",
    "rating": 30
  },
  {
    "name": [
      "ترانه غلامی",
      "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
    ],
    "artworks": "4309",
    "rating": 90
  },
  {
    "name": ["علیرضا فلاحی", "https://i.ibb.co/7p0d1Cd/Frame-24.png"],
    "artworks": "3871",
    "rating": 30
  },
  {
    "name": ["امیرحسین موسوی", "https://i.ibb.co/NSJYQYD/Horizon-UI-Avatar.png"],
    "artworks": "3152",
    "rating": 70
  },
  {
    "name": [
      "محد اسلامی",
      "https://pbs.twimg.com/profile_images/1404161552635371526/251mudeO_400x400.jpg"
    ],
    "artworks": "2907",
    "rating": 30
  },
  {
    "name": [
      "امیرعلی انصاری",
      "https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2244&q=80"
    ],
    "artworks": "9821",
    "rating": 20
  },
  {
    "name": [
      "امیرحسین سیفی",
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
    ],
    "artworks": "7032",
    "rating": 50
  },
  {
    "name": [
      "حسام آشوری",
      "https://images.unsplash.com/photo-1573766064535-6d5d4e62bf9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80"
    ],
    "artworks": "5204",
    "rating": 30
  },
]