import { Helmet } from "react-helmet";
import {
  Banner,
  Information,
  BrokerInformation,
  ActiveModules,
} from "../profile/components";
import CardMenu from "components/card/CardMenu";
import { Card } from "components";
import { useEffect, useState } from "react";

const ProfileOverview = () => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
              setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
      }
  };

  useEffect(()=>{
    console.log(imagePreview)
  },[imagePreview])

  return (
    <>
      <Helmet>
        <title>بورس ویو | پروفایل</title>
        <meta name="description" content=""></meta>
      </Helmet>

      <div className="flex w-full flex-col gap-5">
        <div className="mt-3 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-12">
          <div className="col-span-6 lg:!mb-0">
            <Banner />
          </div>

          {/* <div className="col-span-3 lg:!mb-0">
          <Storage />
        </div> */}

          <div className="z-0 col-span-6 lg:!mb-0">
            <ActiveModules />
          </div>
        </div>
        {/* all project & ... */}

        <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
          <div className="col-span-6  lg:mb-0">
            <BrokerInformation />
          </div>
          <div className="col-span-6 lg:mb-0">
            <Information />
          </div>
        </div>

        <div className="flex gap-3 rounded-[20px] bg-white bg-clip-border p-5 shadow-3xl shadow-shadow-500 dark:!bg-navy-500 dark:text-white dark:shadow-none md-max:flex-col">
          <div className="flex h-[100px] w-full flex-col items-center justify-center gap-3 rounded-[20px] border-2 border-dashed">
            <h3 className="text-gray-400">تصویر اسلاید اول </h3>

            <input
              type="file"
              id="actual-btn"
              accept="image/png, image/gif, image/jpeg"
              hidden
            />
            <label htmlFor="actual-btn" className="rounded-xl bg-gray-600 p-2 text-sm transition-all ease-in hover:bg-gray-300">
             آپلود تصویر
            </label>
          </div>
          <div className="flex  h-[100px] w-full flex-col items-center justify-center gap-3 rounded-[20px] border-2 border-dashed">
            <h3 className="text-gray-400">تصویر اسلاید دوم </h3>
            <input
            onChange={handleImageUpload}
              type="file"
              id="actual-btn2"
              accept="image/png, image/gif, image/jpeg"
              hidden
            />
           <label htmlFor="actual-btn" className="rounded-xl bg-gray-600 p-2 text-sm transition-all ease-in hover:bg-gray-300">
             آپلود تصویر
            </label>
            {
              imagePreview && (
                <img src={imagePreview} style={{width:"150px",height:"150px"}} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileOverview;
